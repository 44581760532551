import { gql } from '@apollo/client';
import { addDays, endOfDay, endOfMonth, endOfYear, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { toUpper } from 'lodash-es';

export const sendToCommerceTable = ( type: string, staffId: string, startDate: Date, endDate: Date, router ) => {
	
	const upperCaseType = toUpper( type );
	
	const filters = [ {
		id   : 'createdAt',
		value: {
			temp: {
				checked              : true,
				operatorValue        : 'range',
				startDate            : startDate,
				endDate              : endDate,
				dayMonthSelectorValue: 'days',
				dayMonthValue        : '',
			},
			$gte: startDate,
			$lte: endDate,
		},
	}, {
		id   : 'type',
		value: {
			$eq : upperCaseType,
			temp: { checked: true },
		},
	}, {
		id   : 'status',
		value: {
			$in : [ 'PAID', 'PARTIALLY_PAID' ],
			temp: { checked: true },
		},
	}, {
		id   : 'staff',
		value: {
			$eq : staffId,
			temp: { checked: true },
		},
	} ];
	
	router.push( {
		pathname: `/dashboard/commerce/${type}s`,
		query   : {
			s: encodeURIComponent( btoa( JSON.stringify( {
				filters,
			} ) ) ),
		},
	}, undefined, { shallow: true } ).then();
	
};

export const getClientStatementFilters = (
	month,
	year,
	selectedTab,
	clientId,
	timezone,
	currentTab,
	customDates,
	excludePaidFromStatement ) => {
	
	if ( !month && !year || !clientId || currentTab === undefined ) return null;
	
	let startDate: string, endDate: string;
	
	if ( month && selectedTab === 'month' && currentTab === 0 ) {
		const formattedMonth = utcToZonedTime( new Date( month ), timezone );
		startDate = startOfMonth( formattedMonth ).toISOString();
		endDate = endOfMonth( formattedMonth ).toISOString();
	} else if ( year && selectedTab === 'year' && currentTab === 0 ) {
		const formattedYear = utcToZonedTime( new Date( year ), timezone );
		startDate = startOfYear( addDays( formattedYear, 1 ) ).toISOString();
		endDate = endOfYear( addDays( formattedYear, 1 ) ).toISOString();
	} else if ( currentTab === 1 && customDates ) {
		startDate = startOfDay( utcToZonedTime( new Date( customDates[ 0 ] ), timezone ) ).toISOString();
		endDate = endOfDay( utcToZonedTime( new Date( customDates[ 1 ] ), timezone ) ).toISOString();
	}
	
	const state = {
		filters: [ {
			id   : 'client.id',
			value: {
				$eq : clientId,
				temp: { checked: true },
			},
		}, {
			id   : 'type',
			value: {
				$eq : 'INVOICE',
				temp: { checked: true },
			},
		}, {
			id   : 'status',
			value: {
				$nin: [ 'DRAFT', 'STANDING', 'MERGED', excludePaidFromStatement ? 'PAID' : null ].filter( Boolean ),
				temp: { checked: true },
			},
		}, {
			id   : [ 'order' ],
			value: {
				$or : [
					{ serviceDate: { $gte: startDate, $lte: endDate } },
					{ createdAt: { $gte: startDate, $lte: endDate } },
				],
				temp: { checked: true },
			},
			
		} ].filter( Boolean ),
	};
	
	return encodeURIComponent( Buffer.from( JSON.stringify( state ) )
		.toString( 'base64' ) );
};

export const ItemsReportRead = gql`query LineItemsRead_1dd1($options: FilterOptions) {
	lineItemsRead(options: $options) {
		items  {
			id # none
			image
			name
			description # none
			price
			quantity
			orderTax
			unit
			status
			code
			sku
			markup
			isRevenue
			cost
			vendorCost
			receivedQuantity
			soldQuantity
			stocked
			metadata
			tags
			item {
				id
				taxable
				name
				removeUom
				tags
				glcode
				externalId
				uoms {
					id
					quantity
					selected
					code
					cost
				}
			}
			order {
				id
				number
				type
				metadata
				externalId
				createdAt
				company {
					name
					tags
				}
				staff {
					email
					createdAt
					user {
						email
						firstName
						lastName
						image
					}
				}
				client {
					name
					contact
				}
				serviceDate
				createdAt
				updatedAt
				paidTotal
			}
			purchase {
				id
				number
				createdAt
				updatedAt
				status
				serviceDate
				paidTotal
				companyLocation {
					address {
						line1
					}
				}
				company {
					name
					tags
				}
				menu {
					name
					vendorName
				}
			}
			category {
				id
				name
				description
			}
			uom {
				id
				name
				price
				cost
				sku
				quantity
				vendorSku
				code
				markup
				min
				max
				msrp
			}
			prices { # none
				id
				name
				isPercent
				value
				quantity
				metadata
				externalId
			}
			tax # none
		}
		count
	}
}`;
