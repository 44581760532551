import Form from '@/components/form';
import GraphqlTable from '@/components/graphqlTable';
import UserPopover from '@/components/userPopover';
import { useGraphQL } from '@/data';
import { LineItemsSalesReport } from '@/data/management/lineItem.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import LineItemTagChip from '@/pages/dashboard/commerce/chips/lineItemTagChip';
import ItemReportQuickFilterActions from '@/pages/dashboard/management/reports/actions/itemReportQuickFilterActions';
import { ItemsReportRead } from '@/pages/dashboard/management/reports/utils';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import type { LineItem, QueryLineItemsReadArgs, QueryLineItemsSalesReportArgs } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { PlaylistAdd as PlaylistAddIcon } from '@mui/icons-material';
import { Avatar, Box, Chip, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { endOfDay, format, startOfMonth } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { round } from 'lodash';
import { isEmpty } from 'lodash-es';
import dynamic from 'next/dynamic';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import {
	CalculateClients,
	CalculateModifiedTotal,
	CalculateNumber,
	CalculateTotal,
	CalculateUnique,
} from '../../commerce/components/tableHelpers';
import ItemReportFilters from './itemReportFilters';
import MobileRowPanel from './mobileRow';

const StatCard = dynamic( () => import( '@/pages/dashboard/main/chart/statCard' ), { ssr: false } );

export const itemSalesHeaders: { header: string, key: string, description: string, type?: string }[] = [
	{
		header     : 'Gross Sales',
		key        : 'grossSales',
		description: 'Total amount of item sales, including extras such as modifiers. This is the gross subtotal before discounts and refunds. Excludes extras such as tips, taxes, or service charges. Formula: Item Gross Sales + Modifier Gross Sales.',
	},
	{
		header     : 'Net Sales',
		key        : 'netSales',
		description: 'Total amount of item and modifier sales, less discounts and refunds. Formula (use to reconcile Items Report with Sales Report): Net Sale = Gross Sale - Discounts - Refunds.',
	},
	{
		header     : 'COGS',
		key        : 'cogs',
		description: 'Cost of goods sold. This value appears only for Revenue Items, does not appear for Non-revenue Items. Formula : COGS = Net Sold Quantity x Cost (as entered in Inventory).',
	},
	{
		header     : 'Gross Profit',
		key        : 'grossProfit',
		description: 'Projected profits based on items with a defined cost. Excludes taxes, service charges, and other expenses. This value appears only for Revenue Items, does not appear for Non-revenue Items. Formula: Gross Profit = Net Sales – COGS *Note: This value may decrease if not all your items have a cost set up.',
	},
	{
		header     : 'Gross Profit Margin',
		key        : 'grossProfitMargin',
		description: 'Gross profit as a percentage of revenue. This value appears only for Revenue Items, does not appear for Non-revenue Items. Formula: (Net Sales – COGS) / Net Sales * 100.',
		type       : 'Percent',
	} ];

export const baseOrderColumns: () => Array<Column<LineItem>> = () => [ {
	accessor      : 'id',
	Header        : 'common:id',
	disableSortBy : true,
	disableFilters: true,
	width         : 10,
	props         : { style: { minWidth: 50 } },
}, {
	accessor: 'name' as any,
	Header  : 'management:name',
	props   : { style: { minWidth: 220 } },
	width   : 20,
	Footer  : ( { rows } ) => CalculateNumber( rows, 'Name' ),
}, {
	accessor   : 'item.glcode' as any,
	Header     : 'GLCode',
	sortType   : 'select',
	filterByKey: 'item.glcode',
	width      : 15,
	Cell       : ( { value } ) => value || '',
	props      : { style: { minWidth: 140 } },
}, {
	accessor   : 'order' as any,
	Header     : '#',
	filterByKey: [ 'order.number', 'order.metadata.customNumber' ],
	width      : 15,
	props      : { style: { minWidth: 140 } },
	Cell       : ( { value } ) =>
		value?.metadata?.customNumber || value?.number || value?.externalId,
	Footer     : ( { rows } ) => CalculateNumber( rows, 'Commerce' ),
}, {
	accessor   : ( row ) => row.order?.client?.name || row.order?.client?.contact,
	Header     : 'management:client',
	filterByKey: [ 'order.client.name', 'order.client.contact' ],
	props      : { style: { minWidth: 135 } },
	width      : 12,
	Footer     : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, {
	accessor   : 'order.staff' as any,
	Header     : 'common:placed-by',
	sortType   : 'select',
	filterByKey: 'order.staff.user.firstName',
	width      : 10,
	props      : { style: { minWidth: 110 } },
	Cell       : ( { value } ) => (
		<UserPopover user={value?.user && { ...value?.user, createdAt: value?.createdAt }}>
			<Chip
				label={value?.user?.firstName}
				size='small'
				avatar={(
					<Avatar
						alt={value?.user?.firstName}
						src={value?.user?.image}
						sx={{
							border     : 1.5,
							borderColor: 'colors.opposite',
						}}
					/>
				)}
			/>
		</UserPopover>
	),
}, {
	accessor   : 'category.name' as any,
	Header     : 'management:category',
	sortType   : 'select',
	filterByKey: 'category.name',
	width      : 15,
	props      : { style: { minWidth: 100 } },
}, {
	accessor     : 'status',
	Header       : 'common:tag',
	disableSortBy: true,
	props        : { style: { minWidth: 135 } },
	width        : 12,
	Cell         : ( { row } ) => (
		<LineItemTagChip
			removePlaceholder
			status={row.original.status}
			company={row.original.order?.company || row.original.purchase?.company}
		/>
	),
}, {
	accessor      : 'order.createdAt' as any,
	Header        : 'management:created-date',
	disableFilters: true,
	filterByKey   : 'order.serviceDate',
	width         : 18,
	props         : { style: { minWidth: 120 } },
	Cell          : ( { value } ) => value ? format( value, 'PPp' ) : ( '' as any ),
}, {
	accessor: 'order.serviceDate' as any,
	Header  : 'common:service-date',
	sortType: 'datetime',
	width   : 18,
	props   : { style: { minWidth: 120 } },
	Cell    : ( { value } ) => value ? format( value, 'PPp' ) : ( '' as any ),
}, {
	accessor      : 'order.companyLocation.address.line1' as any,
	Header        : 'management:location',
	width         : 10,
	disableFilters: true,
	props         : { style: { minWidth: 80 } },
}, {
	accessor      : 'order.type' as any,
	Header        : 'management:type',
	width         : 8,
	disableFilters: true,
	disableSortBy : true,
	props         : { style: { minWidth: 80 } },
	Cell          : ( { value } ) => (
		<Chip
			variant='alpha'
			color={value === 'ORDER' ? 'success' : 'warning'}
			label={value}
		/>
	),
}, {
	accessor: 'quantity',
	Header  : 'management:quantity',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 50 } },
	Footer  : ( { rows } ) => CalculateTotal( rows, 'quantity' ),
}, {
	accessor: 'price',
	Header  : 'management:price',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 80 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'price', true ),
}, {
	accessor: 'cost',
	Header  : 'management:cost',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 80 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'cost', true ),
}, {
	accessor: 'uom.quantity' as any,
	Header  : 'management:stock',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 80 } },
}, {
	accessor: 'unit',
	Header  : 'management:uom',
	width   : 10,
	props   : { style: { minWidth: 80 } },
	Footer  : ( { rows } ) => CalculateUnique( rows, 'unit' ),
}, {
	accessor      : ( row ) => row.quantity * row.price || 0,
	Header        : 'common:total',
	disableFilters: true,
	disableSortBy : true,
	width         : 10,
	props         : { style: { minWidth: 80 } },
	Cell          : ( { value } ) => currencyFormat( value ) as any,
	Footer        : ( { rows } ) => CalculateModifiedTotal( rows ),
}, {
	accessor      : ( row ) => row,
	Header        : 'management:payment',
	disableFilters: true,
	disableSortBy : true,
	width         : 10,
	props         : { style: { minWidth: 80, justifyContent: 'end' } },
	Cell          : ( { value } ) => {
		const lineItemTotal = value.quantity * value.price;
		return currencyFormat( value.order?.paidTotal > lineItemTotal
			? lineItemTotal
			: value.order?.paidTotal ) as any;
	},
} ];

export default function ItemReport() {
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const timezone = getBrowserTimezone();
	const [ status, setStatus ] = useState( '' );
	const [ filterByClient, setFilterByClient ] = useState( null );
	const [ filterByVendor, setFilterByVendor ] = useState( null );
	const [ companyLocation, setCompanyLocation ] = useState( null );
	const [ dates, setDates ] = useState( [ startOfMonth( new Date() ), endOfDay( new Date() ) ] );
	const [ type, setType ] = useState( null );
	const [ placedBy, setPlacedBy ] = useState( null );
	const [ isGrouped, setIsGrouped ] = useState( false );
	const validDates = dates.filter( Boolean );
	
	const { data } = useGraphQL<QueryLineItemsSalesReportArgs>( {
		queryKey : [ 'lineItemsSalesReport' ],
		query    : LineItemsSalesReport,
		variables: {
			timezone,
			dates  : !isEmpty( validDates ) ? validDates
				.map( ( d ) => `${d.toISOString().slice( 0, 10 )} 00:00:00` )
				.map( ( date ) => zonedTimeToUtc( date, timezone ).toISOString() ) : [],
			options: {
				filter: {
					order: {
						client         : filterByClient || undefined,
						companyLocation: companyLocation || undefined,
						type           : type || undefined,
						staff          : isOwner || isAdmin || staff.metadata?.viewOtherEntries
							? placedBy?.id || undefined
							: staff?.id,
					},
				},
			},
		},
	}, {
		keepPreviousData: true,
		enabled         : !!validDates?.length,
	} );
	
	const columns: Column<LineItem>[] = useMemo( () => {
		const columnsToRemoveWhenGrouped = [ '#', 'management:created-date', 'management:type' ];
		const allColumns = baseOrderColumns();
		return isGrouped
			? allColumns.filter( ( column ) => !columnsToRemoveWhenGrouped.includes( column.Header as string ) )
			: allColumns;
	}, [ isGrouped ] );
	
	const itemSalesData = data?.lineItemsSalesReport;
	
	return (
		<Fragment>
			<Form
				initialValues={{
					client         : null,
					companyLocation: null,
					newDates       : [ startOfMonth( new Date() ), endOfDay( new Date() ) ],
					type           : null,
					placedBy       : null,
					menu           : null,
				}}
				onSubmit={( values ) => {
					setFilterByVendor( values.menu?.id );
					setFilterByClient( values.client?.id );
					setCompanyLocation( values.companyLocation?.id );
					setDates( values.newDates );
					setType( values.type );
					setPlacedBy( values.placedBy );
				}}>
				<Fragment>
					<ItemReportFilters type='item'/>
					<Grid container>
						{!isEmpty( validDates ) && itemSalesHeaders.map( ( item ) => (
							<Grid key={item.key} item xs={12} sm={2} sx={{ p: 2 }}>
								<Tooltip title={item.description}>
									<Box>
										<StatCard
											removeGraph
											title={item.header}
											value={item?.type === 'Percent'
												? `${round( itemSalesData?.[ item.key ] || 0, 2 )}%`
												: currencyFormat( itemSalesData?.[ item.key ] )}
											animationVariant='slideLeft'
										/>
									</Box>
								</Tooltip>
							</Grid>
						) )}
					</Grid>
				</Fragment>
			</Form>
			<GraphqlTable<LineItem, QueryLineItemsReadArgs>
				disableUrlSync
				searchable
				showFooter
				queryKey='lineItems'
				query={ItemsReportRead}
				columns={columns}
				hiddenTableColumns='itemReportsHiddenColumns'
				variables={{
					options: {
						filter: {
							store : null,
							status: status || undefined,
							uom   : filterByVendor ? { menus: { $in: [ filterByVendor ] } } : undefined,
							order : {
								client         : filterByClient || undefined,
								companyLocation: companyLocation || undefined,
								type           : type || {
									$nin: [ 'ESTIMATE',
									        'BID',
									        'QUOTE',
									        'PROPOSAL',
									        'WORK_ORDER',
									        'ACCOUNT',
									        'STATEMENT' ],
								},
								deletedAt      : null,
								status         : { $nin: [ 'CANCELLED', 'STANDING' ] },
								staff          : isOwner || isAdmin || staff.metadata?.viewOtherEntries
									? placedBy?.id || undefined
									: staff?.id,
								createdAt      : !isEmpty( validDates )
									? {
										$gte: validDates[ 0 ],
										$lte: validDates[ 1 ],
										
									} : undefined,
							},
						},
					},
				}}
				expandedComponent={( row ) => (
					<Paper sx={{ p: 1, width: '100%', maxHeight: 400, overflow: 'overlay', border: 0 }}>
						<Typography color='text.primary'>
							{row.description
								? t( 'management:description-value', { description: row.description } )
								: t( 'common:no-description' )}
						</Typography>
					</Paper>
				)}
				initialState={{
					hiddenColumns: [ 'id', 'order.companyLocation.address.line1', 'order.serviceDate' ],
				}}
				mobileRenderRow={( lineItem ) => <MobileRowPanel lineItem={lineItem}/>}
				isGroupedBy={isGrouped}
				useQuickFilters={() => ItemReportQuickFilterActions( setStatus )}
				headerExtraActions={{
					items: [ {
						name       : t( 'common:group-items' ) as string,
						icon       : <PlaylistAddIcon/>,
						buttonProps: {
							variant: 'outlined',
							sx     : { animation: isGrouped ? 'ripple2 1s linear infinite' : undefined },
						},
						onClick    : () => setIsGrouped( ( prev ) => !prev ),
					} ],
				}}
			/>
		</Fragment>
	);
}
